<template>
  <span class="d-flex option-box-wrapper">
    <div
      v-for="item in options"
      :key="item.value"
      class="
        square
        d-flex
        flex-column
        align-items-center
        justify-content-center
        text-center
        option-box
      "
      :class="{ active: item.value === value }"
      @click="$emit('input', item.value)"
    >
      <b-icon
        v-show="item.icon"
        :icon="item.icon"
        font-scale="2"
        class="m-1 mb-2"
      />
      <span>{{ item.text }}</span>
      <InfoPopover
        v-show="item.description"
        :text="item.description"
        class="option-box-info"
      />
    </div>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    options: Array,
    value: [Number, String],
  },
  data() {
    return {};
  },
};
</script>
<template>
  <div class="d-flex flex-column h-100">
    <div
      v-if="title"
      class="d-flex align-items-center"
      style="min-height: 38px"
    >
      <strong>{{ title }}</strong>
    </div>
    <div v-if="options" class="d-flex align-items-center">
      <b-form-select
        @change="emitFormChange"
        v-model="component_selected"
        :options="options"
      ></b-form-select>
    </div>
    <div class="mt-3 h-100 d-flex flex-column justify-content-start">
      <div v-for="item in items" :key="item.text" class="dashboard-tab-row">
        <span class="text-muted">{{ item.text }}</span>
        <span class="float-right value">{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleList",
  props: {
    selected: String,
    options: Array,
    title: String,
    items: Array,
    target: String,
  },
  data() {
    return {
      component_selected: this.selected,
    };
  },
  methods: {
    emitFormChange() {
      let selectedElement;
      this.options.forEach((element) => {
        if (element.value == this.component_selected) {
          selectedElement = element;
        }
      });
      this.$emit("CustomEvent_FormChange", {
        target: this.target,
        value: this.component_selected,
        element: selectedElement,
      });
    },
  },
};
</script>
import Validation from '@/utils/Validation'

const ValidationPlugin = {
 install(Vue) {
  Vue.prototype.$validate = function (elementName, element) {
   return (new Validation()).validate(elementName, element);
  }
 }
};

export default ValidationPlugin;
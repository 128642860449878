import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import router from "./router";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/scss/style.scss";

import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import store from "./store";
import { baseAddress, apiAddress, driveAddress, theme } from "@/config/global";

import VTooltipPlugin from "v-tooltip";

import ValidationPlugin from "./plugin/ValidationPlugin";
import ToastPlugin from "./plugin/ToastPlugin";
import AppComponentsPlugin from "./plugin/AppComponentsPlugin";
import JsonExcel from "vue-json-excel";

import Sortable from "sortablejs";

import VueApexCharts from "vue-apexcharts";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import VueLodash from "vue-lodash";
import lodash from "lodash";

import * as VueGoogleMaps from "vue2-google-maps";

import { StripePlugin } from "@vue-stripe/vue-stripe";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.put["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.baseURL = apiAddress;

Vue.prototype.$driveAddress = driveAddress;
Vue.prototype.$baseAddress = baseAddress;
Vue.prototype.$theme = theme;

const options = {
  pk:
    "pk_test_51NdumIHYQKebBvncNgbGb1lr0Hx5KYElh1UHh0zuoGktia97M9ingey77QPDO1n6R9XScvX95L5yT0c8yoP21nif008a8t9SY9",
  apiVersion: "2022-11-15",
  locale: "it",
};

Vue.use(StripePlugin, options);

Vue.use(BootstrapVue, {
  BSidebar: { backdrop: true, noCloseOnBackdrop: true },
});
Vue.use(BootstrapVueIcons);
Vue.use(store);
Vue.use(VTooltipPlugin);

Vue.use(ValidationPlugin);
Vue.use(ToastPlugin);
Vue.use(AppComponentsPlugin);

Vue.use(VueApexCharts);

Vue.use(VueViewer);

Vue.use(VueLodash, { lodash: lodash });

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBI9zgE74Op5MyAu0bXmnfWRy8NZ8bjC4I",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
});

Vue.component("downloadExcel", JsonExcel);
Vue.component("VueTimepicker", VueTimepicker);

Vue.config.productionTip = false;

//To load existing token with a refresh
const token = localStorage.getItem("user-token");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

Vue.directive("sortable-table", function(el, binding, vnode) {
  const table = el;
  table._sortable = Sortable.create(table.querySelector("tbody"), {
    ...binding.value,
    vnode: vnode,
    animation: 150,
    onChoose(e) {
      e.item.placeholder = document.createComment("sort-placeholder");
      e.item.after(e.item.placeholder);
    },
    onSort(e) {
      //put back in original location
      if (e.item.placeholder) {
        e.item.placeholder.replaceWith(e.item);
        delete e.item.placeholder;
      }
    },
    onEnd(e) {
      if (e.newIndex == e.oldIndex) {
        return false;
      }
      //add your logic to save sort and update DOM
      vnode.context.items.splice(
        e.newIndex,
        0,
        vnode.context.items.splice(e.oldIndex, 1)[0]
      );
      vnode.child.$emit("newListOrder", e.oldIndex, e.newIndex);
    },
  });
});

Vue.component("apexchart", VueApexCharts);

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");

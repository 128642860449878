<template>
  <div>
    <b-card class="mt-4 full-width">
      <b-tabs
        v-if="badgesData"
        content-class="p-4 content-border"
        nav-class="dashboard-tabs"
      >
        <b-tab lazy active>
          <template #title>
            <div v-if="loadingBadges">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <title-pie
              v-else
              @CustomEvent_FormChange="changeSelected"
              :options="badgeOptions"
              :selected="badgeSelected"
              :labels="['Won', 'Pending']"
              :pieData="pieData.badgeSelected"
              target="badgeSelected"
              title="Badges"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="badgeSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + badgeSelected"
            ></line-chart>
          </div> -->
        </b-tab>

        <b-tab lazy>
          <template #title>
            <div v-if="loadingAwards">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <title-pie
              @CustomEvent_FormChange="changeSelected"
              :options="awardOptions"
              :selected="awardSelected"
              :labels="['Won', 'Pending']"
              :pieData="pieData.awardSelected"
              target="awardSelected"
              title="Awards"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="awardSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + awardSelected"
            />
          </div> -->
        </b-tab>
      </b-tabs>
      <div v-else>Imposta i valori nel filtro per vederei i risultati</div>
    </b-card>
  </div>
</template>

<script>
// import LineChart from "../LineChart.vue"
import TitlePie from "./TabTitles/TitlePie.vue"

export default {
  name: "BadgesAndAwards",
  components: {
    // LineChart,
    TitlePie,
  },
  props: {
    badgesData: Array,
    awardsData: Array,
    loadingBadges: Boolean,
    loadingAwards: Boolean,
  },

  mounted() {
    this.loadData()
  },
  data() {
    return {
      badgeSelected: "badgesTot",
      awardSelected: "awardsTot",

      badgeOptions: [{ text: "Tutti i badge", value: "badgesTot" }],
      awardOptions: [{ text: "Tutti gli awards", value: "awardsTot" }],

      pieData: {
        badgeSelected: [0, 0],
        awardSelected: [0, 0],
      },

      lineChartData: [],
      lineChartLabels: [],
    }
  },
  methods: {
    loadData() {
      if (this.badgesData) {
        this.badgesData.forEach((element) => {
          element.text = element.nomeBadge
          element.value = element.nomeBadge
          this.badgeOptions.push(element)
        })
      }
      if (this.awardsData) {
        this.awardsData.forEach((element) => {
          element.text = element.nomeBadge
          element.value = element.nomeBadge
          this.awardOptions.push(element)
        })
      }
    },
    changeSelected(event) {
      console.log(event)
      this[event.target] = event.value

      switch (event.target) {
        case "badgeSelected":
          this.pieData.badgeSelected.length = 0
          this.pieData.badgeSelected.push(event.element.won)
          this.pieData.badgeSelected.push(event.element.pending)

          break
        case "awardSelected":
          this.pieData.awardSelected.length = 0
          this.pieData.awardSelected.push(event.element.won)
          this.pieData.awardSelected.push(event.element.pending)

          break
        default:
          this.pieData.badgeSelected = [0, 0]
          this.pieData.awardSelected = [0, 0]
      }

      this.lineChartData.length = 0
      this.lineChartLabels.length = 0

      event.element.vintiGiorno.forEach((element) => {
        this.lineChartData.push(element.ChartData[0])
        this.lineChartLabels.push(element.ChartLabel[0])
      })
    },
  },
  computed: {
    cumulative_lineChartData() {
      let array = []
      let previousPosition = 0
      this.lineChartData.forEach((element) => {
        previousPosition += element
        element = previousPosition
        array.push(element)
      })
      return array
    },
  },
  watch: {
    badgesData: {
      deep: true,
      handler() {
        this.badgeOptions.length = 1
        this.lineChartData.length = 0
        this.lineChartLabels.length = 0

        this.loadData()
      },
    },
    awardsData: {
      deep: true,
      handler() {
        this.awardOptions.length = 1
        this.lineChartData.length = 0
        this.lineChartLabels.length = 0

        this.loadData()
      },
    },
  },
}
</script>

const THEME = {
	localhost: "cpm-theme",
	"bo-fdt.whappy.it": "fdt-theme",
	"bo-people.whappy.it": "people-theme",
	"bo-demo.whappy.it": "demo-theme",
	"bo-trivellato.whappy.it": "trivellato-theme",
	"bo-yeea.whappy.it": "yeea-theme",
	"bo-axerta.whappy.it": "axerta-theme",
	"bo-cpm.whappy.it": "demo-theme",
	"bo-yoube.whappy.it": "yoube-theme",
	"bo-masterclass.whappy.it": "masterclass-theme",
};

const API_URL = {
	DEMO: "https://api-demo.whappy.it",
	FDT: "https://api-fdt.whappy.it",
	PEOPLE: "https://api-people.whappy.it",
	TRIVELLATO: "https://api-trivellato.whappy.it",
	YEEA: "https://api-yeea.whappy.it",
	AXERTA: "https://api-axerta.whappy.it",
	CPM: "https://api-cpm.whappy.it",
	YOUBE: "https://api-yoube.whappy.it",
	MASTERCLASS: "https://api-masterclass.whappy.it",
};

const BASE_ADDRESS = {
	localhost: API_URL.AXERTA,
	"bo-demo.whappy.it": API_URL.DEMO,
	"bo-fdt.whappy.it": API_URL.FDT,
	"bo-people.whappy.it": API_URL.PEOPLE,
	"bo-trivellato.whappy.it": API_URL.TRIVELLATO,
	"bo-yeea.whappy.it": API_URL.YEEA,
	"bo-axerta.whappy.it": API_URL.AXERTA,
	"bo-cpm.whappy.it": API_URL.CPM,
	"bo-yoube.whappy.it": API_URL.YOUBE,
	"bo-masterclass.whappy.it": API_URL.MASTERCLASS,
};

const host = window.location.hostname;
const cleanedHost = host.replace(/[^a-z0-9._-]/gi, "").toLowerCase();

let baseAddress = null;
let theme = null;
if (cleanedHost) {
	baseAddress = BASE_ADDRESS[host] || null;
	theme = THEME[cleanedHost] || null;
}

const settingAddress = baseAddress + "/api/Setting";
const apiAddress = baseAddress + "/api/";
const driveAddress = baseAddress + "/Drive/";

export {
	baseAddress,
	settingAddress,
	apiAddress,
	driveAddress,
	theme,
	API_URL,
};

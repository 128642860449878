<template>
  <div class="content-wrapper text-center">
    <h1 class="mt-5">Page Not Found</h1>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>
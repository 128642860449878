<script>
import { Doughnut } from "vue-chartjs"

export default {
  name: "Doughnut",
  extends: Doughnut,
  props: {
    labels: Array,
    pieData: Array,
    cutoutPercentage: {
      value: Number,
      default: 60,
    },
    legendPosition: {
      type: String,
      default: "right",
    },
    legendDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "My First dataset",
            data: this.pieData,
            fill: true,
          },
        ],
      },
      options: {
        cutoutPercentage: this.cutoutPercentage,
        legend: {
          display: this.legendDisplay,
          position: this.legendPosition,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  created() {
    console.log(this.labels)
    const appElement = document.querySelector("#app")
    const style = getComputedStyle(appElement)
    const primaryColor = style.getPropertyValue("--whappy-primary")
    const secondaryColor = style.getPropertyValue("--whappy-secondary")
    this.data.datasets[0].borderColor = [
      primaryColor,
      secondaryColor,
      "#3993DD",
      "#BA3F1D",
      "#F7B2AD",
      "#A5D0A8",
    ]
    this.data.datasets[0].backgroundColor = [
      primaryColor,
      secondaryColor,
      "#3993DD",
      "#BA3F1D",
      "#F7B2AD",
      "#A5D0A8",
    ]
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
  watch: {
    pieData: {
      deep: true,
      handler() {
        this.data.datasets[0].data = this.pieData
        this.renderChart(this.data, this.options)
      },
    },
  },
}
</script>

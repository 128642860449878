<template>
  <span>
    <b-icon-info-circle :id="id" />
    <b-popover
      :target="id"
      placement="righttop"
      triggers="hover focus"
      :content="text"
    ></b-popover>
  </span>
</template>

<script>
import {v4 as uuid} from 'uuid';
export default {
  props: {
    text: String,
  },
   data() {
    return {
      id: uuid(),
    }
  },
};
</script>
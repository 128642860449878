<template>
  <div class="print-topbar d-flex justify-content-between align-items-center">
    <div
      class="logo-image"
      :style="{ backgroundImage: 'url(' + company.logo + ')' }"
    ></div>
    <span> <strong>MANUAGERE</strong> Sistema</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: mapGetters(["company"]),
}
</script>

<template>
  <div
    :class="[
      'wrapper',
      {
        'desktop-hidden-sidebar': desktopHideSidebar,
        'mobile-show-sidebar': mobileShowSidebar,
      },
    ]"
  >
    <AppMenu
      :model="menu"
      @menuitem-click="onMenuItemClick"
      @menu-toggle="onMenuToggle"
    />
    <AppTopbar
      v-if="showTopbar"
      @menu-toggle="onMenuToggle"
      :showHamburger="showHamburger"
    />
    <PrintTopbar v-else />
    <div class="content">
      <div :class="routerviewClass">
        <router-view />
      </div>
      <AppFooter v-if="showTopbar" />
    </div>
  </div>
</template>

<script>
import AppTopbar from "./AppTopbar"
import PrintTopbar from "./PrintTopbar"
import AppMenu from "./AppMenu.vue"
import AppFooter from "./AppFooter"
import { mapGetters } from "vuex"

export default {
  name: "App",
  components: {
    AppMenu,
    AppTopbar,
    PrintTopbar,
    AppFooter,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      desktopHideSidebar: 0,
      mobileShowSidebar: 0,
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    onMenuToggle() {
      if (this.isDesktop) {
        this.desktopHideSidebar = !this.desktopHideSidebar
      } else {
        this.mobileShowSidebar = !this.mobileShowSidebar
      }
    },
    onMenuItemClick() {
      if (!this.isDesktop) {
        this.mobileShowSidebar = !this.mobileShowSidebar
      }
    },
  },
  computed: {
    isDesktop() {
      return this.windowWidth > 991
    },
    showHamburger() {
      if (this.isDesktop) {
        return this.desktopHideSidebar
      } else {
        //return !this.mobileShowSidebar;
        return 1
      }
    },
    routerviewClass() {
      return {
        "router-view": this.$route.name !== "Print Talent Analysis",
        "white-router-view": this.$route.name === "Print Talent Analysis",
      }
    },
    showTopbar() {
      return this.$route.name !== "Print Talent Analysis"
    },
    ...mapGetters(["menu"]),
  },
}
</script>

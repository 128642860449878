<template>
  <div>
    <b-card class="mt-4 full-width">
      <b-tabs
        v-if="dataSocialShare"
        content-class="p-4 content-border"
        nav-class="dashboard-tabs"
      >
        <b-tab lazy ref="activeOnLoad">
          <template #title>
            <title-list
              @CustomEvent_FormChange="changeSelected"
              :options="landingOptions"
              :selected="landingSelected"
              :items="landingItems"
              target="landingSelected"
            ></title-list>
          </template>
          <div>
            <div class="mt-4 mb-4 d-flex">
              <title-pie
                class="w-100"
                :labels="['Scroll Completed', 'Views']"
                :pieData="[engagementScoreLanding, 100]"
                title="Engagement score landing"
              ></title-pie>
              <title-pie
                class="w-100"
                :labels="['AVG Time', 'Max AVG']"
                :pieData="[avgTime, 100]"
                title="Avarage score landing"
              ></title-pie>
            </div>
            <!-- <div class="d-flex">
              <line-chart
                class="w-100 line-chart"
                :chartData="lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="landingSelected"
              /><line-chart
                class="w-100 line-chart"
                :chartData="cumulative_lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="'Cumulativo: ' + landingSelected"
              />
            </div> -->
            <!-- <div class="googleIframe">
              <iframe
                width="100%"
                height="1500rem"
                src="https://datastudio.google.com/embed/reporting/8c846ef1-c463-4be0-9754-52b3b05e8ac6/page/gGKoC"
                frameborder="0"
                style="border: 0; margin-top: 3rem"
                allowfullscreen
              ></iframe>
            </div> -->
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <title-list
              @CustomEvent_FormChange="changeSelected"
              :options="SocialOptions"
              :selected="SocialSelected"
              :items="SocialItems"
              target="SocialSelected"
            ></title-list>
          </template>
          <div>
            <!-- <div class="mt-4 mb-4 d-flex">
              <title-pie
                class="w-100"
                :labels="['Scroll Completed', 'Views']"
                :pieData="[engagementScoreLanding, 100]"
                title="Engagement score landing"
                caption="Engagement score Landing"
              ></title-pie>
              <title-pie
                class="w-100"
                :labels="['AVG Time', 'X sec.']"
                :pieData="[avgTime, 100]"
                title="Engagement score landing"
                caption="Engagement score Landing"
              ></title-pie>
            </div> -->
            <!-- <div class="d-flex">
              <line-chart
                class="w-100 line-chart"
                :chartData="lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="landingSelected"
              /><line-chart
                class="w-100 line-chart"
                :chartData="cumulative_lineChartData"
                :chartLabels="lineChartLabels"
                :chartTitle="'Cumulativo: ' + landingSelected"
              />
            </div> -->
            <!-- <div class="googleIframe">
              <iframe
                width="100%"
                height="1500rem"
                src="https://datastudio.google.com/embed/reporting/8c846ef1-c463-4be0-9754-52b3b05e8ac6/page/gGKoC"
                frameborder="0"
                style="border: 0; margin-top: 3rem"
                allowfullscreen
              ></iframe>
            </div> -->
          </div>
        </b-tab>
      </b-tabs>
      <div v-else>Imposta i valori nel filtro per vederei i risultati</div>
    </b-card>
  </div>
</template> 

<script>
// import LineChart from "../LineChart.vue";
import TitlePie from "./TabTitles/TitlePie.vue";
import TitleList from "./TabTitles/TitleList.vue";

export default {
  name: "SocialShare",
  components: {
    //     LineChart,
    TitlePie,
    TitleList,
  },

  props: {
    dataSocialShare: Object,
    showGraph: Boolean,
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$parent.localActive && this.dataSocialShare) {
        this.loadData();
      }
    });
  },
  data() {
    return {
      tabIndex: 1,
      landingSelected: "landingTot",
      landingOptions: [{ text: "Tutte le landing", value: "landingTot" }],
      landingItems: [],
      SocialSelected: "socialTot",
      SocialOptions: [{ text: "Tutti i social", value: "socialTot" }],
      SocialItems: [],
      engagementScoreLanding: 0,
      avgTime: 0,
      lineChartData: [],
      lineChartLabels: [],
      data_showGraph: this.showGraph,
    };
  },
  methods: {
    calcTotaliLanding() {
      let totViews = 0;
      let totScroll = 0;
      let totAVG = 0;
      let totCompileForm = 0;
      let totFormStart = 0;

      this.engagementScoreLanding = 0;
      this.avgTime = 0;

      for (let i = 1; i < this.landingOptions.length; i++) {
        totViews = totViews + this.landingOptions[i].totalView;
        totScroll = totScroll + this.landingOptions[i].totalScroll;
        totAVG = totAVG + this.landingOptions[i].AVGTime;

        totCompileForm =
          totCompileForm + this.landingOptions[i].totalCompileForm;
        totFormStart = totFormStart + this.landingOptions[i].totalFormStart;
      }

      if (this.landingOptions.length != 1) {
        this.avgTime = totAVG / (this.landingOptions.length - 1);
      } else {
        this.avgTime = 0;
      }

      this.landingItems.length = 0;

      this.landingItems.push({
        text: "Views",
        value: totViews,
      });
      this.landingItems.push({
        text: "Scroll completed",
        value: totScroll,
      });
      this.landingItems.push({
        text: "AVG Time",
        value: totAVG,
      });
      this.landingItems.push({
        text: "Compiled forms",
        value: totCompileForm,
      });
      this.landingItems.push({
        text: "Forms started",
        value: totFormStart,
      });
      if (this.totScroll != 0 || this.totViews != 0) {
        this.engagementScoreLanding = (totScroll / totViews) * 100 || 0;
      } else {
        this.engagementScoreLanding = 0;
      }
    },
    calcTotaliSocial() {
      let totLikes = 0;
      let totComments = 0;
      let totShares = 0;

      for (let i = 1; i < this.SocialOptions.length; i++) {
        totLikes = totLikes + this.SocialOptions[i].like;
        totComments = totComments + this.SocialOptions[i].comment;
        totShares = totShares + this.SocialOptions[i].share;
      }

      this.SocialItems.length = 0;

      this.SocialItems.push({
        text: "Likes",
        value: totLikes,
      });
      this.SocialItems.push({
        text: "Comments",
        value: totComments,
      });
      this.SocialItems.push({
        text: "Shares",
        value: totShares,
      });
    },
    loadData() {
      this.dataSocialShare.landing.forEach((element) => {
        element.text = element.nome;
        element.value = element.nome;

        this.landingOptions.push(element);
      });
      this.dataSocialShare.social.forEach((element) => {
        element.text = element.nome;
        element.value = element.nome;

        this.SocialOptions.push(element);
      });
      this.calcTotaliLanding();
      this.calcTotaliSocial();
    },
    changeSelected(event) {
      this[event.target] = event.value;

      switch (event.target) {
        case "landingSelected":
          this.landingItems.length = 0;

          if (event.value != "landingTot") {
            this.landingItems.push({
              text: "Views",
              value: event.element.totalView,
            });
            this.landingItems.push({
              text: "Scroll completed",
              value: event.element.totalScroll,
            });
            this.landingItems.push({
              text: "AVG Time",
              value: event.element.AVGTime,
            });
            this.landingItems.push({
              text: "Compiled forms",
              value: event.element.totalCompileForm,
            });
            this.landingItems.push({
              text: "Forms started",
              value: event.element.totalFormStart,
            });

            this.lineChartData.length = 0;
            this.lineChartLabels.length = 0;

            this.engagementScoreLanding = 0;
            this.avgTime = 0;

            if (event.element.totalScroll == 0 || event.element.totViews == 0) {
              this.engagementScoreLanding = 0;
            } else {
              this.engagementScoreLanding =
                (event.element.totalScroll / event.element.totViews) * 100;
            }

            this.avgTime = event.element.AVGTime;

            event.element.ViewGiorno.forEach((element) => {
              this.lineChartData.push(element.ChartData[0]);
              this.lineChartLabels.push(element.ChartLabel[0]);
            });
          } else {
            this.calcTotaliLanding();
          }
          break;
        case "SocialSelected":
          this.SocialItems.length = 0;
          if (event.value != "socialTot") {
            this.SocialItems.push({
              text: "Likes",
              value: event.element.like,
            });
            this.SocialItems.push({
              text: "Comments",
              value: event.element.comment,
            });
            this.SocialItems.push({
              text: "Shares",
              value: event.element.share,
            });
          } else {
            this.calcTotaliSocial();
          }
      }
    },
  },
  computed: {
    cumulative_lineChartData() {
      let array = [];
      let previousPosition = 0;
      this.lineChartData.forEach((element) => {
        previousPosition += element;
        element = previousPosition;
        array.push(element);
      });
      return array;
    },
  },
  watch: {
    dataSocialShare: {
      deep: true,
      handler() {
        this.landingOptions.length = 1;
        this.lineChartData.length = 0;
        this.lineChartLabels.length = 0;
        this.engagementScoreLanding = 0;

        this.loadData();
      },
    },
  },
};
</script>
<template>
  <div>
    <b-card class="mt-4 full-width">
      {{ selected }}
      <b-tabs
        v-if="dataKpi"
        content-class="p-4 content-border"
        nav-class="dashboard-tabs"
      >
        <b-tab lazy active>
          <template #title>
            <div v-if="loadingKpi">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <title-pie
              v-else
              @CustomEvent_FormChange="changeSelected"
              :options="options"
              :selected="selected"
              :labels="['Utenti che hanno completato', 'Utenti totali']"
              :pieData="pieData.firstChart"
              :target="'selected'"
            ></title-pie>
          </template>
          <!-- <line-chart
            class="w-100 line-chart"
            :chartData="data"
            :options="chartOptions"
          /> -->
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div v-if="loadingKpi">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <title-pie
              v-else
              :title="'Media valore gruppo'"
              :labels="['Perc. completamento media del gruppo', 'Goal']"
              :pieData="pieData.secondChart"
              :caption="selected"
            ></title-pie>
          </template>

          <!-- <line-chart
            class="w-100 line-chart"
            :chartData="data"
            :options="chartOptions"
          /> -->
        </b-tab>
      </b-tabs>
      <div v-else>Imposta i valori nel filtro per vederei i risultati</div>
    </b-card>
  </div>
</template>

<script>
// import LineChart from "../LineChart.vue";
import TitlePie from "./TabTitles/TitlePie.vue"

export default {
  name: "GlobalData",
  components: {
    // LineChart,
    TitlePie,
  },
  props: {
    dataKpi: Array,
    loadingKpi: Boolean,
  },
  created() {
    console.log(this.dataKpi)
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$parent.localActive && this.dataKpi) {
        this.loadData()
      }
    })
  },
  data() {
    return {
      selected: "kpiTot",
      options: [{ text: "Tutti i Kpi", value: "kpiTot" }],

      pieData: {
        firstChart: [0, 0],
        secondChart: [0, 0],
      },
    }
  },
  methods: {
    loadData() {
      this.options.splice(1)
      this.selected = "kpiTot"

      this.dataKpi.forEach((element) => {
        element.text = element.nomeKpi
        element.value = element.nomeKpi

        this.options.push(element)
      })
    },
    changeSelected(event) {
      console.log(event)
      this[event.target] = event.value

      this.pieData.firstChart.length = 0
      this.pieData.firstChart.push(event.element.utentiCompletato)
      this.pieData.firstChart.push(event.element.utentiTotale)

      this.pieData.secondChart.length = 0
      this.pieData.secondChart.push(event.element.mediaValore)
      this.pieData.secondChart.push(event.element.mediaGoal)
    },
  },
  watch: {
    dataKpi: {
      deep: true,
      handler() {
        this.options.splice(1)
        this.selected = "kpiTot"

        this.loadData()
      },
    },
  },
}
</script>

const ToastPlugin = {
 install(Vue) {
  Vue.prototype.$successToast = function (customMessage = "") {
   this.$root.$bvToast.toast(customMessage || "Operation completed.", {
    title: "Success",
    variant: "success",
    solid: true,
    toaster: "b-toaster-bottom-right",
   });
  }

  Vue.prototype.$errorToast = function (customMessage = "") {
   this.$root.$bvToast.toast(customMessage || "Operation not completed.", {
    title: "Error",
    variant: "danger",
    solid: true,
    toaster: "b-toaster-bottom-right",
   });
  }

 }
};



export default ToastPlugin;
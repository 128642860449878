<template>
  <div class="login-wrapper">
    <form class="form-signin" @submit.prevent="login">
      <img class="logo mb-1" :src="company.logo" />
      <h1 class="h3 mb-4 font-weight-normal">{{ company.nome }}</h1>

      <b-form-input
        v-model="username"
        placeholder="Username"
        autofocus
        class="mb-2"
      ></b-form-input>
      <b-form-input
        v-model="password"
        type="password"
        placeholder="Password"
        class="mb-4"
      ></b-form-input>

      <button class="btn btn-lg btn-primary btn-block" type="submit">
        Sign in
      </button>
    </form>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "../store/actions/auth"
import store from "../store"
import { mapGetters } from "vuex"
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    }
  },
  methods: {
    login: function() {
      const authObject = {
        username: this.username,
        password: this.password,
      }
      this.$store.dispatch(AUTH_REQUEST, authObject).then(() => {
        this.$router.push("/")
      })
    },
  },
  beforeMount() {
    if (store.getters.isAuthenticated) {
      this.$router.push("/")
    }
  },
  computed: {
    ...mapGetters(["company"]),
  },
}
</script>

import axios from 'axios'

const url_options = "Option/";
const url_upload = "Upload/";
const url_actionUtility = "ActionUtility/";

export default class AxiosService {

	constructor(url) {
		this.url = url + "/";
	}

	create(item, params = []) {
		const queryString = AxiosService.getQueryString(params);
		return axios.post(this.url + queryString, item).then(res => res.data);
	}

	read(params = []) {
		const queryString = AxiosService.getQueryString(params);
		return axios.get(this.url + queryString).then(res => res.data);
	}

	readById(id, params = []) {
		const queryString = AxiosService.getQueryString(params);
		return axios.get(this.url + id + queryString).then(res => res.data);
	}

	readCustomEndpoint(endpoint, value) {
		let address = endpoint;
		if (value) address += "/" + value;
		return axios.get(address).then(res => res.data);
	}

	postCustomEndpoint(endpoint, value, item) {
		let address = endpoint;
		if (value) address += "/" + value;
		return axios.post(address, item).then(res => res.data);
	}

	putCustomEndpoint(endpoint, value, item) {
		let address = endpoint;
		if (value) address += "/" + value;
		return axios.put(address, item).then(res => res.data);
	}

	deleteCustomEndpoint(endpoint, value) {
		return axios.delete(endpoint + "/" + value).then(res => res.data);
	}

	update(item) {
		let name = this.url;
		return axios.put(name + (item.id || item.ID), item).then(res => res.data);
	}

	updateParams(item, params = []) {
		const queryString = AxiosService.getQueryString(params);
		let name = this.url;
		return axios.put(name + queryString, item).then(res => res.data);
	}

	delete(itemId) {
		return axios.delete(this.url + itemId).then(res => res.data);
	}

	getOptions(optionName) {
		return axios.get(url_options + optionName).then(res => res.data);
	}

	uploadFile(formData) {
		return axios.post(url_upload, formData, { 'Content-Type': 'multipart/form-data' }).then(res => res)
	}

	updateOrder(newOrder, apiName) {
		let name = this.url;
		if (apiName) name = apiName;
		return axios.put("Order/" + name, newOrder).then(res => res.data);
	}

	getActionUtility(actionName, idCampaign) {
		return axios.get(url_actionUtility + actionName + "/" + idCampaign).then(res => res.data);
	}

	static getQueryString(params) {
		let queryString = "";
		params.forEach((x) => {
			queryString += queryString ? "&" : "?";
			queryString += x.param + "=" + x.value;
		});
		return queryString;
	}

}
<template>
  <div>
    <b-card class="mt-4 full-width">
      <b-tabs
        v-if="dataUsersPie"
        content-class="p-4 content-border"
        nav-class="dashboard-tabs"
      >
        <b-tab lazy active>
          <template #title>
            <div v-if="loadingPie">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <title-pie
              v-else
              @CustomEvent_FormChange="changeSelected"
              :options="options"
              :selected="selected"
              :showComponentSelected="false"
              :labels="[selected, 'Totali']"
              :pieData="[computed_items, dataUsersPie.totaleUtenti]"
              :target="'selected'"
            >
            </title-pie>
          </template>
          <div class="d-flex">
            <div v-if="loadingBars">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <div v-else class="w-100 line-chart">
              <line-chart
                :chartData="dataUsersBars.values"
                :chartLabels="dataUsersBars.labels"
                chartTitle="Utenti attivi"
              />
            </div>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div v-if="loadingSessions">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>

            <title-pie
              v-else
              :labels="['Sessioni', 'Utenti']"
              :pieData="dataSessionsPie"
              :value="(dataSessionsPie[1] / dataSessionsPie[0]).toFixed(2)"
              :showPercentage="false"
              title="Analitycs"
            ></title-pie>
          </template>
          <div class="d-flex">
            <div v-if="loadingSessions">
              <b-icon
                icon="hourglass-bottom"
                animation="spin"
                font-scale="4"
              ></b-icon>
            </div>
            <div class="d-flex w-100" v-else>
              <div class="w-100 line-chart">
                <line-chart
                  class="w-100"
                  :chartData="dataSessionsBars.valori_G1"
                  :chartLabels="dataSessionsBars.etichette_G1"
                  chartTitle="Sessioni attive"
                />
              </div>
              <div class="w-100 line-chart">
                <line-chart
                  class="w-100"
                  :chartData="dataSessionsBars.valori_G2"
                  :chartLabels="dataSessionsBars.etichette_G2"
                  chartTitle="Cumulativo sessioni attive"
                />
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div v-else>Imposta i valori nel filtro per vederei i risultati</div>
    </b-card>
  </div>
</template>

<script>
import LineChart from "../LineChart.vue"
import TitlePie from "./TabTitles/TitlePie.vue"

export default {
  name: "GlobalData",
  components: {
    LineChart,
    TitlePie,
  },
  props: {
    dataUsersPie: Object,
    dataUsersBars: Object,
    loadingPie: Boolean,
    loadingBars: Boolean,
    loadingSessions: Boolean,
    dataSessionsBars: Object,
    dataSessionsPie: Array,
    showGraph: Boolean,
  },
  data() {
    return {
      selected: "Attivi",
      options: [
        { text: "All Users", value: "Totali" },
        { text: "With Facebook", value: "Facebook" },
        { text: "With LinkedIn", value: "LinkedIn" },
        { text: "Attivi", value: "Attivi" },
        { text: "Non attivi", value: "Non Attivi" },
        // { text: "Progress complete", value: "Progress Complete" },
      ],

      chartLabels_ApiUsage: [],
      chartData_ApiUsage: [],

      api_call: 0,
      total_api: 0,

      currentPage: 1,
      perPage: 10,
      fields: ["nome", "Ruolo", "attivo", "progress"],
    }
  },

  methods: {
    changeSelected(event) {
      this[event.target] = event.value
    },
  },
  computed: {
    computed_items() {
      // let array = [];
      let valore = 0
      let filter = this.selected
      switch (filter) {
        case "Attivi":
          valore = this.dataUsersPie.utentiAttivi

          break
        case "Totali":
          valore = this.dataUsersPie.totaleUtenti

          break
        case "Facebook":
          valore = this.dataUsersPie.utentiFacebook

          break
        case "LinkedIn":
          valore = this.dataUsersPie.utentiLinkedin

          break
        case "Non Attivi":
          valore = this.dataUsersPie.utentiNonAttivi

          break
        case "Progress Complete":
          valore = this.dataUsersPie.utentiComplete

          break
      }
      return valore
    },
  },
}
</script>

<script>
import { Line } from "vue-chartjs"

export default {
  extends: Line,
  props: ["chartLabels", "chartData", "chartTitle"],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart(this.computed_data, this.options)
      // console.log("RENDERed CHART");
    })
  },
  computed: {
    computed_data() {
      let obj = {
        labels: this.chartLabels,
        datasets: [
          {
            label: this.chartTitle,
            borderColor: getComputedStyle(
              document.documentElement
            ).getPropertyValue("--whappy-primary"),
            data: this.chartData,
            fill: false,
          },
        ],
      }
      return obj
    },
  },
  watch: {
    computed_data() {
      this.renderChart(this.computed_data, this.options)
    },
  },
}
</script>

<template>
  <b-form-radio-group
    v-model="selectedLang"
    :options="options"
    button-variant="secondary"
    size="sm"
    name="radio-btn"
    buttons
    class="sub-form-buttons"
  ></b-form-radio-group>
</template>
<script>
export default {
  props: ["value", "options"],
  computed: {
    selectedLang: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      },
    },
  },
};
</script>
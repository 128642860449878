import InfoPopover from '@/components/InfoPopover';
import LangOptions from '@/components/LangOptions';
import TagsInput from '@/components/TagsInput';
import BoxOptions from '@/components/BoxOptions';
import ViewLangSelect from '@/components/ViewLangSelect';

export default {
 install(Vue) {
  Vue.component('InfoPopover', InfoPopover);
  Vue.component('LangOptions', LangOptions);
  Vue.component('TagsInput', TagsInput);
  Vue.component('BoxOptions', BoxOptions);
  Vue.component('ViewLangSelect', ViewLangSelect);
 }
}

export { InfoPopover, LangOptions, TagsInput, BoxOptions, ViewLangSelect };

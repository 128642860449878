<template>
  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-column title-pie-title">
      <div v-if="title">
        {{ title }}
      </div>
      <div v-if="options" class="d-flex align-items-center">
        <b-form-select
          @change="emitFormChange"
          v-model="component_selected"
          :options="options"
        ></b-form-select>
        <!-- <b-icon-filter class="pointer ml-2" id="filter1" />
        <b-popover target="filter1" triggers="click" placement="auto">
          <b-form-checkbox-group
            v-model="component_selected"
            :options="options"
            stacked
          ></b-form-checkbox-group
        ></b-popover> -->
      </div>
      <InfoPopover v-if="infoPopover" class="ml-1" :text="infoPopover" />
    </div>
    <div
      class="
        h-100
        d-flex
        align-items-center
        justify-content-center
        fraction-container
      "
    >
      <div class="d-flex flex-column align-items-end position-relative">
        <doughnut :labels="labels" :pieData="computed_pieData"></doughnut>
        <div v-if="showPercentage" class="percentage-absolute">
          <p style="font-size: 1rem">
            {{ percentage }}

            <span v-if="percentage.length > 0">%</span>
            <span v-else> - </span>
          </p>
        </div>
        <div v-else class="d-flex flex-column percentage-absolute">
          <div>
            <p style="font-size: 1rem; line-heigth: 1%">
              {{ computed_value }}
              <span style="font-size: 1rem">{{ value_etichetta }}</span>
            </p>
          </div>
          <div>
            <!-- <span class="apice etichettaPieAbsolute">Avg.</span> -->
          </div>
        </div>
      </div>
      <div class="ml-3 fraction-text">
        <div class="big">
          <p v-if="selected && showComponentSelected" class="mb-1">
            {{ component_selected }}
          </p>
          <p v-else class="mb-1">
            {{ caption }}
          </p>
          <div v-if="showPercentage">
            <p>
              <span :style="'color:' + this.primaryColor">{{ labels[0] }}</span>
              /
              <span :style="'color:' + this.secondaryColor">{{
                labels[1]
              }}</span>
            </p>
            <span :style="'color:' + this.primaryColor">{{
              pieData[0].toFixed(0)
            }}</span
            >/<span :style="'color:' + this.secondaryColor">{{
              pieData[1].toFixed(0)
            }}</span>
          </div>
          <div v-else>
            <p>
              <span :style="'color:' + this.secondaryColor">{{
                labels[0]
              }}</span>
              /
              <span :style="'color:' + this.primaryColor">{{ labels[1] }}</span>
              <span v-if="labels[2]" :style="'color:' + this.secondaryColor">{{
                labels[2]
              }}</span>
            </p>
            <span :style="'color:' + this.secondaryColor">{{
              pieData[1].toFixed(0)
            }}</span
            >/<span :style="'color:' + this.primaryColor">{{
              pieData[0].toFixed(0)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Doughnut from "../../DoughnutChart.vue"
import InfoPopover from "../../InfoPopover.vue"
export default {
  name: "TitlePie",
  components: {
    Doughnut,
    InfoPopover,
  },
  props: {
    title: String,
    options: Array,
    selected: String,
    infoPopover: String,
    caption: String,
    labels: Array,
    pieData: Array,
    target: String,
    showComponentSelected: {
      type: Boolean,
      default: true,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
    value: String,
    value_etichetta: {
      type: String,
      default: "avg",
    },
  },
  data() {
    return {
      component_selected: this.selected,
      primaryColor: "",
      secondaryColor: "",
    }
  },
  created() {
    const appElement = document.querySelector("#app")

    const style = getComputedStyle(appElement)

    this.primaryColor = style.getPropertyValue("--whappy-primary")
    this.secondaryColor = style.getPropertyValue("--whappy-secondary")
  },

  methods: {
    emitFormChange() {
      let selectedElement
      this.options.forEach((element) => {
        if (element.value == this.component_selected) {
          selectedElement = element
        }
      })
      this.$emit("CustomEvent_FormChange", {
        target: this.target,
        value: this.component_selected,
        element: selectedElement,
      })
    },
  },
  computed: {
    computed_pieData() {
      if (this.pieData[0] == 0 && this.pieData[1] == 0) {
        return [0, 1]
      } else {
        if (this.showPercentage) {
          if (this.pieData[0] < this.pieData[1]) {
            return [this.pieData[0], this.pieData[1] - this.pieData[0]]
          } else if (this.pieData[1] == 0) {
            return [this.pieData[0], 0]
          } else {
            return [this.pieData[0], 0]
          }
        } else {
          return [this.pieData[0], this.pieData[1]]
        }
      }
    },
    percentage() {
      if (
        isNaN(((this.pieData[0] / this.pieData[1]) * 100).toFixed(0)) ||
        isNaN(this.pieData[0] || isNaN(this.pieData[1]))
      ) {
        return "0"
      } else if (this.pieData[1] == 0) {
        return ""
      } else {
        return ((this.pieData[0] / this.pieData[1]) * 100).toFixed(0)
      }
    },
    computed_value() {
      if (this.value == "NaN") {
        return "0"
      } else if (this.value == undefined) {
        return ""
      } else {
        return this.value
      }
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.component_selected = this.options[0].value || null
      },
    },
  },
}
</script>

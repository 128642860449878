<template>
  <div>
    <b-card class="mt-4 full-width">
      <b-tabs
        v-if="dataLearning"
        content-class="p-4 content-border"
        nav-class="dashboard-tabs"
      >
        <b-tab lazy active>
          <template #title>
            <title-pie
              @CustomEvent_FormChange="changeSelected"
              :options="sectionsOptions"
              :selected="sectionSelected"
              :labels="['Completed', 'Total']"
              :pieData="pieData.sectionSelected"
              target="sectionSelected"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="sectionSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + sectionSelected"
            />
          </div> -->
        </b-tab>
        <b-tab lazy>
          <template #title>
            <title-pie
              @CustomEvent_FormChange="changeSelected"
              :options="chaptersOptions"
              :selected="chapterSelected"
              :labels="['Completed', 'Total']"
              :pieData="pieData.chapterSelected"
              target="chapterSelected"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="chapterSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + chapterSelected"
            />
          </div> -->
        </b-tab>
        <b-tab lazy>
          <template #title>
            <title-pie
              @CustomEvent_FormChange="changeSelected"
              :options="questionOptions"
              :selected="questionSelected"
              target="questionSelected"
              :labels="['Corrects', 'Answered']"
              :pieData="pieData.correctAnswers"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="chapterSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + chapterSelected"
            />
          </div> -->
        </b-tab>
      </b-tabs>
      <div v-else>Imposta i valori nel filtro per vederei i risultati</div>
    </b-card>
  </div>
</template>

<script>
// import LineChart from "../LineChart.vue";
import AxiosService from "../../axiosServices/AxiosService"
import TitlePie from "./TabTitles/TitlePie.vue"

export default {
  name: "Learning",
  components: {
    //     LineChart,
    TitlePie,
  },
  props: {
    dataLearning: Object,
    loadingLearning: Boolean,
  },
  service: null,

  mounted() {
    this.$nextTick(() => {
      if (this.$parent.localActive && this.dataLearning) {
        this.loadData()
      }
    })
  },
  data() {
    return {
      sectionSelected: "All sections",
      sectionsOptions: [{ text: "All sections", value: "All sections" }],

      pieData: {
        sectionSelected: [0, 0],
        chapterSelected: [0, 0],
        correctAnswers: [0, 0],
      },

      chapterSelected: null,
      chaptersOptions: [{ text: "All chapters", value: null }],

      questionSelected: null,
      questionOptions: [{ text: "All Questions", value: null }],

      lineChartData: [],
      lineChartLabels: [],
    }
  },

  methods: {
    loadData() {
      console.log("this data learning -> ", this.dataLearning)
      this.dataLearning.data.forEach((element) => {
        element.text = element.nomeBadge
        element.value = element.nomeBadge

        this.sectionsOptions.push(element)
      })
    },
    changeSelected(event) {
      console.log(
        "🚀 ~ file: Learning.vue:146 ~ changeSelected ~ event:",
        event
      )
      this[event.target] = event.value

      switch (event.target) {
        case "sectionSelected":
          if (this.sectionSelected != "All sections") {
            this.pieData.sectionSelected.splice(0)
            this.pieData.sectionSelected = [
              event.element.won,
              event.element.pending,
            ]
            this.pieData.chapterSelected.splice(0)
            this.pieData.correctAnswers.splice(0)

            this.getChapters()
          }
          this.chapterSelected = null
          break
        case "chapterSelected":
          if (this.chapterSelected != "All chapters") {
            this.pieData.chapterSelected.splice(0)
            this.pieData.chapterSelected = [
              event.element.won,
              event.element.pending,
            ]
            this.getQuestions()
            this.pieData.correctAnswers.splice(0)
          }
          break
        case "questionSelected":
          if (this.questionSelected != "All questions") {
            this.pieData.correctAnswers.splice(0)
            this.pieData.correctAnswers = [
              event.element.corrette,
              event.element.risposte,
              // event.element.totali
            ]
          }
          break
      }
    },
    getChapters() {
      this.chaptersOptions.splice(1)
      const service = new AxiosService("Dashboard/GetLearningChaptersNew")
      let objectToPost = { ...this.dataLearning.objectToPost }

      let idSection = this.sectionsOptions.filter(
        (x) => x.text == this.sectionSelected
      )[0].id

      objectToPost.idSezioneLearning = idSection

      service.create(objectToPost).then((res) => {
        res.forEach((element) => {
          this.chaptersOptions.push({
            text: element.nomeBadge,
            value: element.nomeBadge,
            ...element,
          })
        })
      })
    },
    getQuestions() {
      this.questionOptions.splice(1)
      const service = new AxiosService("Dashboard/GetLearningQuestionsNew")
      let objectToPost = { ...this.dataLearning.objectToPost }

      let idSection = this.sectionsOptions.filter(
        (x) => x.text == this.sectionSelected
      )[0].id

      let idChapter = this.chaptersOptions.filter(
        (x) => x.text == this.chapterSelected
      )[0].id

      objectToPost.idSezioneLearning = idSection
      objectToPost.idCapitoloLearning = idChapter

      service.create(objectToPost).then((res) => {
        res.forEach((element) => {
          this.questionOptions.push({
            text: element.domanda,
            value: element.domanda,
            ...element,
          })
        })
      })
    },
  },
  computed: {
    cumulative_lineChartData() {
      let array = []
      let previousPosition = 0
      this.lineChartData.forEach((element) => {
        previousPosition += element
        element = previousPosition
        array.push(element)
      })
      return array
    },
  },
  watch: {
    dataLearning: {
      deep: true,
      handler() {
        this.sectionsOptions.length = 1
        this.chaptersOptions.length = 1
        this.lineChartData.length = 0
        this.lineChartLabels.length = 0

        this.loadData()
      },
    },
  },
}
</script>
